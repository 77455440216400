import {graphql, Link, useStaticQuery} from "gatsby"
import React from "react"
import slugify from "../../functions/slugify"

interface GuidesList {
  archive: boolean
  title: string
}

const GuidesOverview = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsGuide(sort: {order: ASC, fields: position}) {
        nodes {
          archive
          title
        }
      }
    }
  `)
  return (
    <div className="guides_overview">
      {data.allDatoCmsGuide.nodes.map(({archive, title}: GuidesList) => {
        return (
          <div className="dotted" key={title}>
            <Link
              to={`/handreikingen/${slugify(title)}`}
              className={`clearfix${archive ? "light_blue" : ""}`}
            >
              <span className="name">{title}</span>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default GuidesOverview
