import {graphql, Link, useStaticQuery} from "gatsby"
import React from "react"

interface PublicationsList {
  number: number
  numberSuffix: string | null
  menuName: string
  obsolete: boolean
  pgsLabel: string
}

const PublicationsOverview = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsPublication(sort: {fields: number}) {
        nodes {
          number
          numberSuffix
          menuName
          obsolete
          pgsLabel
        }
      }
    }
  `)

  return (
    <div className="publications_overview">
      {data.allDatoCmsPublication.nodes.map(
        ({
          number,
          numberSuffix,
          menuName,
          pgsLabel,
          obsolete,
        }: PublicationsList) => {
          return (
            <div className="dotted" key={menuName}>
              <Link
                to={`/publicaties/${pgsLabel.toLowerCase()}`}
                className={`clearfix ${obsolete ? "light_blue" : ""}`}
              >
                <span className={`number ${numberSuffix ? "suffix" : ""}`}>
                  {number}
                  <span>{numberSuffix && `-${numberSuffix}`}</span>
                </span>
                <span className="name">{menuName}</span>
              </Link>
            </div>
          )
        },
      )}
    </div>
  )
}

export default PublicationsOverview
