import React from "react"

interface Props {
  years: {
    planEnd: Date
    planStart: Date
    curEnd: Date
    curStart: Date
  }
  listComponent?: boolean
}

interface YearTimeline {
  planned: {startPos: number; length: number}
  current: {startPos: number; length: number}
}

const Planning = (props: Props) => {
  const currentYear = new Date().getFullYear()
  const comingYears = [currentYear, currentYear + 1, currentYear + 2]

  const yearRange = (revisionStart: number, revisionEnd: number) => {
    const output = []
    for (let i = revisionStart; i <= revisionEnd; i++) {
      output.push(i)
    }
    return output
  }

  const diffDays = (firstDate: Date, secondDate: Date) => {
    return (
      Math.round(Math.abs((+firstDate - +secondDate) / (1000 * 60 * 60 * 24))) /
      365
    )
  }

  const planningTimeline = (
    planStart: Date,
    planEnd: Date,
    curStart: Date,
    curEnd: Date,
  ) => {
    const timeline: YearTimeline[] = []
    comingYears.forEach(year => {
      const yearTimeline = {
        planned: {startPos: 0, length: 1},
        current: {startPos: 0, length: 1},
      }
      const yearStart = new Date(year, 0, 1)
      const yearEnd = new Date(year, 11, 31)
      if (
        yearRange(planStart.getFullYear(), planEnd.getFullYear()).includes(year)
      ) {
        if (planStart.getFullYear() === year) {
          yearTimeline.planned.startPos = diffDays(planStart, yearStart)
          yearTimeline.planned.length = diffDays(
            planEnd.getFullYear() === year ? planEnd : yearEnd,
            planStart,
          )
        }
        if (planEnd.getFullYear() === year) {
          yearTimeline.planned.length = diffDays(
            planEnd,
            planStart.getFullYear() === year ? planStart : yearStart,
          )
        }
      } else {
        yearTimeline.planned.length = 0
      }
      if (
        yearRange(curStart.getFullYear(), curEnd.getFullYear()).includes(year)
      ) {
        if (curStart.getFullYear() === year) {
          yearTimeline.current.startPos = diffDays(curStart, yearStart)
          yearTimeline.current.length = diffDays(
            curEnd.getFullYear() === year ? curEnd : yearEnd,
            curStart,
          )
        }
        if (curEnd.getFullYear() === year) {
          yearTimeline.current.length = diffDays(
            curEnd,
            curStart.getFullYear() === year ? curStart : yearStart,
          )
        }
      } else {
        yearTimeline.current.length = 0
      }
      timeline.push(yearTimeline)
    })
    return timeline
  }

  const planning = planningTimeline(
    props.years.planStart,
    props.years.planEnd,
    props.years.curStart,
    props.years.curEnd,
  )

  return (
    <div className="planning">
      {comingYears.map((year, index) => {
        return (
          <>
            <div
              className={"planned current"}
              style={{
                marginLeft: `${
                  (planning[index].current.startPos + index) * 33.333333333333
                }%`,
                width: `${planning[index].current.length * 33.333333333333}%`,
                zIndex: "3",
              }}
              key={`${year} current`}
            >
              <span className="linewrap"></span>
              {(!planning[index + 1] ||
                planning[index + 1].current.length === 0) && (
                <>
                  <span>{year.toString().slice(2)}</span>
                  <span>20</span>
                </>
              )}
            </div>
            <div
              className={"planned"}
              style={{
                marginLeft: `${
                  (planning[index].planned.startPos + index) * 33.333333333333
                }%`,
                width: `${planning[index].planned.length * 33.333333333333}%`,
                zIndex: "2",
              }}
              key={`${year} planned`}
            >
              <span className="linewrap"></span>
              {(!planning[index + 1] ||
                planning[index + 1].planned.length === 0) && (
                <>
                  <span>{year.toString().slice(2)}</span>
                  <span>20</span>
                </>
              )}
            </div>
            <div
              className={`year ${year % 2 === 0 ? "even" : "odd"}`}
              style={{
                marginLeft: `${33.333333333333 * index}%`,
                width: "33.333333333333%",
                zIndex: "1",
              }}
              key={`${year} year`}
            >
              <span className="linewrap"></span>
              {!props.listComponent && (
                <>
                  <span>{year.toString().slice(2)}</span>
                  <span>20</span>
                </>
              )}
            </div>
          </>
        )
      })}
    </div>
  )
}

export default Planning
