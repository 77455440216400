import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import Planning from "./publications/planning"

const PlanningList = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsPublication(
        sort: {fields: [number, numberSuffix], order: [ASC, ASC]}
        filter: {showInPlanning: {eq: true}}
      ) {
        nodes {
          pgsLabel
          plannedRevisionEnd
          plannedRevisionStart
          currentRevisionStart
          currentRevisionEnd
          obsolete
          menuName
        }
      }
    }
  `)
  const currentYear = new Date().getFullYear()
  const comingYears = [currentYear, currentYear + 1, currentYear + 2]
  return (
    <>
      <ul className="legend">
        <li className="planned">
          <span></span>Planning
        </li>
        <li className="current">
          <span></span>Huidige herzieningen
        </li>
      </ul>
      <div className="revision header">
        <div className="planning">
          {comingYears.map((year, index) => {
            return (
              <div
                className={`year ${year % 2 === 0 ? "even" : "odd"}`}
                style={{
                  marginLeft: `${33.333333333333 * index}%`,
                  width: "33.333333333333%",
                  zIndex: "1",
                }}
                key={`${year} year`}
              >
                <span className="linewrap"></span>
                <span>{year.toString().slice(2)}</span>
                <span>20</span>
              </div>
            )
          })}
        </div>
      </div>
      {data.allDatoCmsPublication.nodes.map(
        (pub: {
          pgsLabel: string
          plannedRevisionEnd: string
          plannedRevisionStart: string
          currentRevisionStart: string
          currentRevisionEnd: string
          obsolete: boolean
          menuName: string
        }) => {
          return (
            <div
              className={`revision ${pub.obsolete && "obsolete"}`}
              key={pub.pgsLabel}
            >
              <div className="link">
                <a href={`/publicaties/${pub.pgsLabel.toLowerCase()}`}>
                  {pub.menuName}
                </a>
              </div>
              <h2>{pub.pgsLabel.slice(3)}</h2>
              <Planning
                years={{
                  planEnd: new Date(pub.plannedRevisionEnd),
                  planStart: new Date(pub.plannedRevisionStart),
                  curEnd: new Date(pub.currentRevisionEnd),
                  curStart: new Date(pub.currentRevisionStart),
                }}
                listComponent={true}
              />
            </div>
          )
        },
      )}
    </>
  )
}

export default PlanningList
