import React from "react"
import {useStaticQuery, graphql} from "gatsby"

interface Link {
  title: string
  url: string
  description?: string
}

const Links = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsLinkSection(
        filter: {links: {elemMatch: {active: {eq: true}}}}
      ) {
        nodes {
          title
          links {
            title
            url
            description
          }
        }
      }
    }
  `)
  return (
    <>
      {data.allDatoCmsLinkSection.nodes.map(
        (section: {title: string; links: Link[]}, index: number) => (
          <div key={index}>
            <h2 className="dotted">{section.title}</h2>
            <div className="links">
              <ul>
                {section.links.map((link, index) => (
                  <li key={index}>
                    <a href={link.url} target="_blank" rel="noreferrer">
                      <span className="link">
                        {link.title}
                        <span className="icon-external-link"></span>
                      </span>
                      <span className="description">{link.description}</span>
                      <span className="url">
                        {`${link.url}`
                          .replace("https://", "www.")
                          .replace("http://", "www.")}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ),
      )}
    </>
  )
}

export default Links
