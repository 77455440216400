import React from "react"
import {graphql} from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Links from "../components/links/links"
import GuidesOverview from "../components/guides/guides-overview"
import PublicationsOverview from "../components/publications/publications-overview"
import PlanningList from "../components/planning-list"
import {SEO} from "../components/seo"

interface PageQuery {
  data: {
    datoCmsPage: {
      body: string
    }
  }
  pageContext: {
    url: string
    title: string
    publicationType: string
    id: string
    menuName?: string
  }
}

const PageTemplate = ({data, pageContext}: PageQuery) => {
  const {body} = data.datoCmsPage
  const {title, url} = pageContext
  return (
    <Layout currentLocation={url}>
      <h1>{title}</h1>
      {parse(body)}
      {url === "links" && <Links />}
      {url === "handreikingen" && <GuidesOverview />}
      {url === "publicaties" && <PublicationsOverview />}
      {url === "planning" && <PlanningList />}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    datoCmsPage(id: {eq: $id}) {
      body
    }
  }
`

export default PageTemplate

export const Head = ({pageContext}: PageQuery) => {
  return <SEO title={pageContext.title} />
}
